import React, { useEffect, useRef } from "react";
import Styles from "./Home.module.css";
import { icons } from "../../constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import banner_side_img from "../../assets/Images/Banner/banner_side_img.svg";
import mobile_banner_img from "../../assets/Images/Banner/mobile_banner_img.png";

import puzzle_img from "../../assets/Icons/EasyandFun/puzzle_img.svg";
import Sound_img2 from "../../assets/Icons/EasyandFun/sound__img_2.svg";
import ball_img from "../../assets/Icons/EasyandFun/ball_img.svg";
import A_img from "../../assets/Icons/EasyandFun/A_img.svg";
// import Sound_img2 from "../../assets/Icons/EasyandFun/sound__img_2.svg";
import C_img from "../../assets/Icons/EasyandFun/C_img.svg";
import Sound_img1 from "../../assets/Icons/EasyandFun/sound__img_1.svg";
import B_img from "../../assets/Icons/EasyandFun/B_img.svg";

import Fun_learning from "../../assets/Images/Banner/Fun_learning.svg";
import owlbody from "../../assets/Images/Banner/character/owlbody.png";
import owlhand from "../../assets/Images/Banner/character/owlhand.png";
import lolabody from "../../assets/Images/Banner/character/lolabody.png";
import lolahand from "../../assets/Images/Banner/character/lolahand.png";
import benbody from "../../assets/Images/Banner/character/benbody.png";
import benhand from "../../assets/Images/Banner/character/benhand.png";
import Tombody from "../../assets/Images/Banner/character/Tombody.png";
import tomhand from "../../assets/Images/Banner/character/tomhand.png";
import elibody from "../../assets/Images/Banner/character/elibody.png";
import elihand from "../../assets/Images/Banner/character/elihand.png";
// bee_1
import Bee from "../../assets/Images/Banner/bee_1.gif";

export default function Banner() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  // learnign made easy and fun
  useEffect(() => {
    const randomX = random(1, 10);
    const randomY = random(1, 10);
    const randomTime = random(3, 6);
    const randomTime2 = random(5, 10);
    const randomAngle = random(-10, 50);

    const cans = gsap.utils.toArray("#triggerfunlearning > img");

    cans.forEach((can) => {
      gsap.set(can, {
        x: randomX(-1),
        y: randomX(1),
        rotation: randomAngle(-1),
      });

      moveX(can, 1);
      moveY(can, -1);
      rotate(can, 1);
    });

    function rotate(target, direction) {
      gsap.to(target, randomTime2(), {
        rotation: randomAngle(direction),
        // delay: randomDelay(),
        onComplete: rotate,
        onCompleteParams: [target, direction * -1],
      });
    }

    function moveX(target, direction) {
      gsap.to(target, randomTime(), {
        x: randomX(direction),

        onComplete: moveX,
        onCompleteParams: [target, direction * -1],
      });
    }

    function moveY(target, direction) {
      gsap.to(target, randomTime(), {
        y: randomY(direction),

        onComplete: moveY,
        onCompleteParams: [target, direction * -1],
      });
    }

    function random(min, max) {
      const delta = max - min;
      return (direction = 1) => (min + delta * Math.random()) * direction;
    }
  }, []);

  // Hover Animation
  // useEffect(() => {
  //   const tl = gsap.timeline()
  //   document.querySelector("#owlbodyAnimation").addEventListener("mouseenter", () => {
  //     // animate the "hovered" image
  //     tl.to("#owlbodyAnimation", { x: -30, y: -10, scale: 1.1 }, "<")
  //       .to("#owlhandAnimation", { x: -30, y: -10, scale: 1.1 }, 0);

  //   });
  //   // when the mouse leaves, animate everything back to "normal".
  //   document.querySelector("#owlbodyAnimation").addEventListener("mouseleave", () => {
  //     tl.to("#owlbodyAnimation", { scale: 1, x: 0 }, "<")
  //       .to("#owlhandAnimation", { scale: 1, x: 0 }, 0);

  //   })
  // }, [])
  useEffect(() => {
    // lolaHand
    [
      document.querySelector("#lolaBody"),
      document.querySelector("#lolaHand"),
    ].forEach((type) => {
      type.addEventListener("mouseenter", () => {
        document.getElementById("lolaHand").style.left = "-65px";
        document.getElementById("lolaBody").style.right = "10px";
      });
      type.addEventListener("mouseleave", () => {
        document.getElementById("lolaHand").style.left = "-40px";
        document.getElementById("lolaBody").style.right = "-20px";
      });
    });
  }, []);

  useEffect(() => {
    // Ben
    [
      document.querySelector("#benbody"),
      document.querySelector("#benhand"),
    ].forEach((type) => {
      type.addEventListener("mouseenter", () => {
        document.getElementById("benhand").style.left = "-75px";
        document.getElementById("benbody").style.right = "5px";
      });
      type.addEventListener("mouseleave", () => {
        document.getElementById("benhand").style.left = "-47px";
        document.getElementById("benbody").style.right = "-20px";
      });
    });
  }, []);

  useEffect(() => {
    // tom
    [
      document.querySelector("#Tombody"),
      document.querySelector("#tomhand"),
    ].forEach((type) => {
      type.addEventListener("mouseenter", () => {
        document.getElementById("tomhand").style.left = "-70px";
        document.getElementById("Tombody").style.right = "5px";
      });

      type.addEventListener("mouseleave", () => {
        document.getElementById("tomhand").style.left = "-42px";
        document.getElementById("Tombody").style.right = "-15px";
      });
    });
  }, []);

  useEffect(() => {
    // Eli
    [
      document.querySelector("#elibody"),
      document.querySelector("#elihand"),
    ].forEach((type) => {
      type.addEventListener("mouseenter", () => {
        document.getElementById("elihand").style.left = "-79px";
        document.getElementById("elibody").style.right = "10px";
      });
      type.addEventListener("mouseleave", () => {
        document.getElementById("elihand").style.left = "-47px";
        document.getElementById("elibody").style.right = "-25px";
      });
    });
  }, []);

  useEffect(() => {
    // let tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: "#beeAnimationTrigger",
    //     start: "top 20%",
    //     end: "bottom",

    //     markers: true,
    //     // scrub: true,
    //     // pin: "#CenterImage",
    //   },

    // });

    gsap.to(".bessAnimation", {
      x: "random(-500, 500)",
      y: "random(-500, 500)",
      duration: 6,
      yoyo: true,
      repeat: -1,
      repeatRefresh: true,
    });
    // document.querySelector(".bessAnimation").addEventListener("mouseover", hoverOn);
    // function hoverOn() {
    //   gsap.to(".bessAnimation", { x: 1 })
    // }
  }, []);

  // useEffect(() => {
  //   let tl = gsap.timeline({
  //     scrollTrigger: {
  //       markers: true,
  //       trigger: "#beeAnimationTrigger",
  //       pin: true,
  //       start: "top 50%",
  //       end: "bottom",

  //       scrub: 1,

  //       toggleActions: "play pause resume reset",

  //       onEnterBack: ({ progress, direction, isActive }) => {
  //         console.log(progress, direction, isActive);
  //       },

  //       onLeave: ({ progress, direction, isActive }) => {
  //         console.log(progress, direction, isActive);
  //       },

  //       invalidateOnRefresh: true,

  //     }
  //   });

  //   tl

  //     .to("#ibeesIcon", { x: -300, y: () => { return - (window.innerHeight / 2 - ((window.innerHeight * 0.75 * 0.2) / 2)) } });

  // })

  return (
    <div className={Styles.bannerContainer} ref={ref}>
      {/* <div className='beeAnimation'>
      </div> */}

      <div className={Styles.leftContainer}>
        <div className={Styles.icons_shape}>
          <img
            src={banner_side_img}
            alt="blueShape"
            className={Styles.blueShape}
            id="trigger_one"
          />
        </div>
        <div className={Styles.bannerText} id="beeAnimationTrigger">
          <div>
            <h3>Welcome to</h3>
            <span className="ibeesIconContainer">
              <img
                src={icons.Ibeesnavbar}
                alt="icons"
                className="ibeesIcons"
                id="ibeesIcon"
              />
              <img src={Bee} alt="Bee" className="bessAnimation" />
            </span>
          </div>

          <div className={Styles.funlearning_container}>
            <img
              src={Fun_learning}
              alt="Fun_learning"
              className={Styles.mainImg}
            />
            <div id="triggerfunlearning" className={Styles.triggerfunlearning}>
              <img
                src={puzzle_img}
                alt="puzzle_img"
                className={Styles.image_one}
              />
              <img
                src={Sound_img2}
                alt="Sound_img2"
                className={Styles.image_four}
              />
              <img src={ball_img} alt="ball_img" className={Styles.image_two} />
              <img src={A_img} alt="A_img" className={Styles.image_three} />
              <img src={C_img} alt="C_img" className={Styles.image_six} />
              <img
                src={Sound_img1}
                alt="Sound_img1"
                className={Styles.image_five}
              />
              <img src={B_img} alt="B_img" className={Styles.image_seven} />
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.rightContainer}>
        <div className={Styles.BannerMobi}>
          <img src={mobile_banner_img} alt="mobile_banner_img" />
        </div>

        <div className={Styles.rightContainer_sub}>
          <div className={Styles.whiteOuter}>
            <img
              src={owlbody}
              alt="owlbody"
              className={Styles.owlbody}
              id="owlbodyAnimation"
            />
            <img
              src={owlhand}
              alt="owlbody"
              className={Styles.owlhand}
              id="owlhandAnimation"
            />
          </div>

          <div className={Styles.white}>
            <img
              src={lolabody}
              alt="lolabody.png"
              className={Styles.lolabody}
              id="lolaBody"
            />
          </div>
          <div className={Styles.green} id="greenAnim">
            <img
              src={lolahand}
              alt="owlbody"
              className={Styles.lolahand}
              id="lolaHand"
            />
            <img
              src={benbody}
              alt="benbody"
              className={Styles.benbody}
              id="benbody"
            />
          </div>
          <div className={Styles.blue}>
            <img
              src={benhand}
              alt="benhand"
              className={Styles.benhand}
              id="benhand"
            />

            <img
              src={Tombody}
              alt="Tombody"
              className={Styles.benbody}
              id="Tombody"
            />
          </div>
          <div className={Styles.yellow}>
            <img
              src={tomhand}
              alt="tomhand"
              className={Styles.tomhand}
              id="tomhand"
            />
            <img
              src={elibody}
              alt="elibody"
              className={Styles.elibody}
              id="elibody"
            />
          </div>
          <div className={Styles.violet}>
            <img
              src={elihand}
              alt="elihand"
              className={Styles.elihand}
              id="elihand"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
