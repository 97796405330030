/*  created by daniya*/
import React from "react";
import Styles from "./Testimonial.module.css";
import { images, icons } from "../../constants";
// import { testimonialDatas } from "../../data/Data";

export default function Testimonial() {
  // const [allTestimonialDetails, setAllTestimonialDetails] = useState("");
  // useEffect(() => {
  //   setAllTestimonialDetails(testimonialDatas);
  // }, []);

  return (
    <div className={Styles.testimonialContainer}>
      <div className={Styles.bannerImage}>
        <img src={images.TestimonialBanner} alt="testimonialBanner" />
      </div>
      <div className={Styles.gridImageContainer}>
        <div className={Styles.Image1}>
          <img src={images.Image1} alt="image1" />
        </div>
        <div className={Styles.Image2}>
          <img src={images.Image2} alt="image2" />
        </div>
        <div className={Styles.gridContainer}>
          {/* {allTestimonialDetails &&
                  allTestimonialDetails.map((item) => (
                    <Data
                      data={item}
                    />
                  ))} */}

          <div className={Styles.gridImageDiv1}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv2}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the when an unknown printer took a
                galley of type and scrambled it to make a type specimen
                book.when an unknown printer took a galley of type and scrambled
                it to make a type specimen book.
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv3}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv4}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv5}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv6}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv7}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv8}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
          <div className={Styles.gridImageDiv9}>
            <div className={Styles.imageDiv}>
              <img src={images.TestimonialImage} alt="testimonialImage" />
            </div>
            <div className={Styles.gridItems}>
              <div className={Styles.headingImage}>
                <h3>Jhon Deo</h3>
                <img src={images.QuotationMark} alt="quotationMark" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.MLorem
                Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
