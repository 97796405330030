/*  created by daniya*/
import Image1 from "../assets/Images/testimonials/image_1.svg";
import Image2 from "../assets/Images/testimonials/Image_2.svg";
import QuotationMark from "../assets/Images/testimonials/quotation_marks.svg";
import TestimonialBanner from "../assets/Images/testimonials/Testimonials banner.svg";
import TestimonialImage from "../assets/Images/testimonials/Testimonials_img.png";
import AppleIcon from "../assets/Images/Footer/Apple_icon.svg";
import GoogleIcon from "../assets/Images/Footer/Google_icon.svg";
import FooterIcons from "../assets/Images/Footer/i_bees_logo_footer.png";
import SocialMediaIcons from "../assets/Images/Footer/socialmediaicons.svg";
// import BannerImg from "../assets/Images/Footer/CONTACT_US_banner_img.svg";
import MapImg from "../assets/Images/Footer/Map_bg.svg";
import SubscriptionBanner from "../assets/Images/subscriptions/Subscriptions_banner.svg";
import IbeesLogo from "../assets/Images/subscriptions/i bees_Subscriptions_plan_logo.png";
import SubscriptionImage from "../assets/Images/subscriptions/Subscriptions_img.svg";
// import ContactBannerImg from "../assets/Images/contactus/CONTACT_US_banner_img.svg";
import ContactImg from "../assets/Images/contactus/Contact_us_bg.svg";
import subscriptionPlanBanner from "../assets/Images/subscriptionPlan/your_plan_banner.svg";
import yearAmount from "../assets/Images/subscriptionPlan/year_amount.svg";
import backGroundImageLeft from "../assets/Images/subscriptionPlan/bg_img_left.svg";
import backGroundImageRight from "../assets/Images/subscriptionPlan/bg_img_right.svg";
import learnAndPlayBanner from "../assets/Images/LearnAndPlay/banner_img.svg";
import learnAndPlayBackground from "../assets/Images/LearnAndPlay/BG_img.svg";

export default {
  Image1,
  Image2,
  QuotationMark,
  TestimonialBanner,
  TestimonialImage,
  AppleIcon,
  GoogleIcon,
  FooterIcons,
  SocialMediaIcons,
  MapImg,
  SubscriptionBanner,
  IbeesLogo,
  SubscriptionImage,
  // ContactBannerImg,
  ContactImg,
  subscriptionPlanBanner,
  yearAmount,
  backGroundImageLeft,
  backGroundImageRight,
  learnAndPlayBanner,
  learnAndPlayBackground,
};
