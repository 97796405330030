import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineMenuUnfold } from "react-icons/ai";
// import { MdOutlineClose } from "react-icons/md";
import "./NavBar.css";
import IbLogo from "../../assets/Icons/IB.png";
import closeBtn from "../../assets/Images/Header/mob/close_icon.svg";
import Leftimg from "../../assets/Images/Header/mob/left_img.svg";
import right_img from "../../assets/Images/Header/mob/right_img.svg";

import Try_the_app_buttion from "../../assets/Images/Header/mob/Try_the_app_buttion.svg";
import Try_the_app_buttionDESKTOP from "../../assets/Icons/Try_the_app_button.svg";
import Bee from "../../assets/Images/Banner/bee_1.gif";
import { icons } from "../../constants";

function NavBar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);


  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink to="/" className="nav-logo">
            <img src={IbLogo} alt="IbLogo" />{" "}
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                to="/"
                activeclassname="NavBar_active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/about"
                activeclassname="NavBar_active" className="nav-links"
                onClick={click ? handleClick : null}
              >
                About
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                to="/Testimonial"
                activeclassname="NavBar_active" className="nav-links"
                onClick={click ? handleClick : null}
              >
                Testimonials
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/Contact"
                activeclassname="NavBar_active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Contact Us
              </NavLink>
            </li>
            <li className="nav-itemTry">
              <div className="tryTheApp">
                <NavLink
                  to="/LearnAndPlay"
                  
                  activeclassname="NavBar_active"
                  className="nav-linksTry"
                  onClick={click ? handleClick : null}
                  // className="tryTheAppButtonText"
                >
                  <img
                    src={Try_the_app_buttionDESKTOP}
                    alt="Try_the_app_buttionDESKTOP"
                    className="tryTheAppButton"
                  />
                  <span className="tryTheAppSpan">Try the app</span>
                </NavLink>
              </div>
            </li>

            <img
              src={icons.Ibeesnavbar}
              alt="IbeesMobNav"
              className="IbeesMobNav"
            />

            <img src={Leftimg} alt="Leftimg" className="Leftimg" />

            <img src={right_img} alt="right_img" className="right_img" />

            <NavLink to="/LearnAndPlay" onClick={click ? handleClick : null}>
              <div>
                <img
                  src={Try_the_app_buttion}
                  alt="Try_the_app_buttion"
                  className="Try_the_app_buttion"
                />
                {/* <img src={Bee} alt="Bee" className="tryTheApp_bee" /> */}
              </div>
            </NavLink>
          </ul>
          <div onClick={handleClick}>
            {click ? (
              <img src={closeBtn} alt="closeBtn" className="nav-icon-Close" />
            ) : (
              <AiOutlineMenuUnfold
                color=" #9267d4"
                size={35}
                className="nav-icon"
              />
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
