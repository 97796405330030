import React from "react";
import Banner from "./Banner";
import Second from "./Second";
import Testimonial from '../Testimonials/Testimonial'
export default function Home() {
  return (
    <div>
      {/* <Testimonial/> */}
      <Banner />
      <Second />
    </div>
  );
}
