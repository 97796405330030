import "./App.css";
import React, { Suspense, lazy } from "react";

import { Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import ScrollTop from "./components/Scrollto_Top";

const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Gallery = lazy(() => import("./pages/Gallery/Gallery"));
const Testimonial = lazy(() => import("./pages/Testimonials/Testimonial"));
// const Subscriptions = lazy(() => import("./pages/Subscriptions/Subscriptions"));
// const SubscriptionPlan = lazy(() =>
//   import("./pages/subscription_Plan/SubscriptionPlan")
// );
const SubscriptionPlanApp = lazy(() =>
  import("./pages/subscription_Plan/SubscriptionPlanApp")
);
const LearnAndPlay = lazy(() => import("./pages/Learn_And_Play/LearnAndPlay"));
const Contact = lazy(() => import("./pages/Contact/Contact"));

const PrivacyAndPolicy = lazy(() => import("./pages/PrivacyAndPolicy"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition"));
const ReturnandRefundPolicy = lazy(() =>
  import("./pages/ReturnandRefundPolicy")
);
const ShippingandDeliveryPoliy = lazy(() =>
  import("./pages/ShippingandDeliveryPoliy")
);

function App() {
  return (
    <div>
      <ScrollTop />

      <NavBar />
      <div style={{ marginTop: "70px" }}>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          draggable
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          // style={{ width: "500px" }}
        />
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Testimonial" element={<Testimonial />} />
            {/* <Route path="/Subscriptions" element={<Subscriptions />} />
            <Route path="/SubscriptionPlan/:duration/:price" element={<SubscriptionPlan />} /> */}
            <Route
              path="/sapp/:userId"
              element={<SubscriptionPlanApp />}
            />

            <Route path="/LearnAndPlay" element={<LearnAndPlay />} />

            <Route path="/Contact" element={<Contact />} />
            <Route path="/Privacy" element={<PrivacyAndPolicy />} />
            <Route path="/Terms" element={<TermsAndCondition />} />
            <Route path="/Return" element={<ReturnandRefundPolicy />} />
            <Route path="/Shipping" element={<ShippingandDeliveryPoliy />} />
          </Routes>
        </Suspense>

        <Footer />
      </div>
    </div>
  );
}

export default App;
