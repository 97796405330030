import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// import { MotionPathPlugin } from "gsap/MotionPathPlugin";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
// import { FaQuoteRight } from "react-icons/fa";
// import { TbUserCircle } from "react-icons/tb";
import Styles from "./Home.module.css";
import Preschoolathome from "../../assets/Images/EasyandFun/Preschool_at_home.png";
import CenterImage from "../../assets/Images/EasyandFun/game_Image_1.png";
import CenterImage_left from "../../assets/Images/EasyandFun/game_Image_left.png";
import CenterImage_right from "../../assets/Images/EasyandFun/game_Image_right.png";
import pyramid from "../../assets/Images/EasyandFun/5.svg";
import puzzle_img from "../../assets/Images/EasyandFun/1.svg";
import Rightflight from "../../assets/Images/EasyandFun/2.svg";
import Leftflight from "../../assets/Images/EasyandFun/3.svg";

import Rhymes from "../../assets/Images/Rhymes.png";
import Stories from "../../assets/Images/Stories.png";
import ParentalAccess from "../../assets/Images/BrainGrowth/ParentalAccess.png";
import purple from "../../assets/Images/BrainGrowth/purple.svg";
import green from "../../assets/Images/BrainGrowth/green.svg";
import Tomastronaut from "../../assets/Images/BrainGrowth/Tomastronaut.png";

export default function Second() {
  gsap.registerPlugin(ScrollTrigger);

  // gsap responsive
  let mm = gsap.matchMedia();

  // const ButtonGroup = ({ next, previous, ...rest }) => {
  //   const {
  //     carouselState: { currentSlide },
  //   } = rest;
  //   return (
  //     <div className={Styles.carousel_button_group}>
  //       <div
  //         className={currentSlide === 0 ? "disable" : ""}
  //         onClick={() => previous()}
  //       >
  //         <button className={Styles.carosuelBtn}>
  //           <MdArrowBackIosNew color="#438cff" size={30} />
  //         </button>
  //       </div>
  //       <div>
  //         <button onClick={() => next()} className={Styles.carosuelBtn}>
  //           <MdArrowForwardIos color="#438cff" size={30} />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  useEffect(() => {
    let ctx = gsap.context(() => {
      mm.add("(min-width: 800px)", () => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#CenterImage",
            start: "top",
            end: "center",

            // markers: true,
            scrub: true,
            pin: "#CenterImage",
            pinSpacing: true,
          },
        });

        tl.fromTo(
          "#CenterImage",
          {
            scale: 1,
          },
          {
            scale: 0.37,
            yPercent: -20,
            // ease: "ease-in",
          },
          "<"
        );
        tl.to(
          ".ImageText",
          {
            yPercent: 100,
            bottom: 0,
            color: "#672fd9",
            scale: 1,
          },
          "<"
        );

        tl.fromTo(
          "#CenterImage2",
          {
            opacity: 0,
            rotate: 0,
          },
          {
            opacity: 1,
            rotate: 10,
          }
        );
        tl.fromTo(
          "#CenterImage3",
          {
            opacity: 0,
            rotate: 0,
            display: "none",
          },
          {
            opacity: 1,
            rotate: -10,
            display: "block",
          }
        );
        // Pyramid
        tl.fromTo(
          "#Pyramid",
          {
            opacity: 0,
            rotate: 0,
            display: "none",
          },
          {
            opacity: 1,
            display: "block",
          }
        );
      });
    });

    return () => ctx.revert(); // cleanup!
  }, [mm]);

  useEffect(() => {
    mm.add("(min-width: 800px)", () => {
      let t3 = gsap.timeline({
        scrollTrigger: {
          trigger: "#Brain_Growth",
          start: "top 50%",
          // markers: true,
          scrub: true,
        },
      });

      t3.fromTo(
        "#Brain_Growth_img",
        {
          xPercent: -10,
        },
        {
          xPercent: 0,
        },
        "<"
      );
      t3.fromTo(
        "#Brain_Growth_para",
        {
          xPercent: 10,
        },
        {
          xPercent: 0,
        },
        "<"
      );
    });
  }, [mm]);

  // Parental Access

  useEffect(() => {
    mm.add("(min-width: 800px)", () => {
      let t4 = gsap.timeline({
        scrollTrigger: {
          trigger: "#parentalAccess",
          start: "top 80%",
          // markers: true,
          scrub: true,
        },
      });

      t4.fromTo(
        "#parentalAccess_img",
        {
          xPercent: -20,
          // yPercent: -50,
        },
        {
          xPercent: -30,
        },
        "<"
      );
      t4.fromTo(
        "#parentalAccess_para",
        {
          xPercent: -1,
        },
        {
          xPercent: 0,
        },
        "<"
      );
    });
  }, [mm]);

  // expertly
  useEffect(() => {
    mm.add("(min-width: 800px)", () => {
      let t5 = gsap.timeline({
        scrollTrigger: {
          trigger: "#expertlyTrigger",
          start: "top 90%",
          end: "top",
          // markers: true,
          scrub: true,
        },
        ease: "circ.out",
      });

      t5.fromTo(
        "#expertly",
        {
          yPercent: -20,
          // yPercent: -50,
        },
        {
          yPercent: 0,
        },
        "<"
      );
      t5.fromTo(
        "#expertlyTest",
        {
          yPercent: 25,
        },
        {
          yPercent: 0,
        },
        "<"
      );
    });
  }, [mm]);
  //  carosuel
  // useEffect(() => {
  //   mm.add("(min-width: 800px)", () => {

  //     let t5 = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#carosuelTrigger",
  //         start: "top 50%",
  //         end: "center",
  //         // markers: true,
  //         // ease: "circ.out",
  //       },
  //     });

  //     t5.from("#carousel", {
  //       xPercent: 20,
  //       duration: 2,
  //     });
  //   })
  // }, []);
  // tomastronaut
  useEffect(() => {
    mm.add("(min-width: 800px)", () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#Brain_Growth",
          start: "0",
          end: "bottom",
          // markers: true,
          scrub: true,
        },
      });

      tl.fromTo(
        "#tomastronaut",
        {
          yPercent: 0,
        },
        {
          yPercent: -30,
          xPercent: -30,
          // ease: "ease-in",
        }
      );
    });
  }, [mm]);

  return (
    <>
      {/* Learning fun */}
      <div className={Styles.home_second_container} id="trigger2">
        <div className={Styles.home_second_sub_container}>
          <h2 className={Styles.firsth1} id="learningmade">
            Play, learn
          </h2>
          <h2 className={Styles.firsth2}>and grow together</h2>
        </div>
      </div>
      {/* Images
       */}
      <div className={Styles.homeBgClr}>
        <div className={Styles.CenterImage} id="CenterImage">
          <div>
            <img src={CenterImage} alt="CenterImage" />
            <img
              src={Rightflight}
              alt="Rightflight"
              className={Styles.Rightflight}
              id="Pyramid"
            />
            <img
              src={Leftflight}
              alt="Leftflight"
              className={Styles.Leftflight}
              id="Pyramid"
            />

            <h1 className="ImageText">Preschool at home</h1>

            <div className={Styles.bullets}>
              <ul>
                <li>Learning through fun and games</li>
                <li>
                  Enjoy vast variety of activities, games, stories, video
                  lessons, rhymes etc.
                </li>
                <li>
                  Lessons contributing in various fields including Science, Math
                  and Logic, English etc.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={CenterImage_left}
            alt="CenterImage_left"
            className={Styles.image_let}
            id="CenterImage2"
          />
          <img
            src={puzzle_img}
            alt="puzzle_img"
            className={Styles.puzzle_img}
            id="Pyramid"
          />

          <img
            src={CenterImage_right}
            alt="CenterImage_right"
            className={Styles.image_right}
            id="CenterImage3"
          />
          <img
            src={pyramid}
            alt="pyramid"
            className={Styles.pyramid}
            id="Pyramid"
          />
        </div>

        {/* Mobile */}
        <div className={Styles.CenterImageMobile}>
          <div>
            <img src={Preschoolathome} alt="Preschoolathome" />
            <div className={Styles.bullets}>
              <h1 className="">Preschool at home</h1>

              <ul>
                <li>Learning through fun and games</li>
                <li>
                  Enjoy vast variety of activities, games, stories, video
                  lessons, rhymes etc.
                </li>
                <li>
                  Lessons contributing in various fields including Science, Math
                  and Logic, English etc.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Improve Child's Brain Growth */}

        <div className={Styles.mainContainer}>
          <div className={Styles.bgImage}>
            <div className={Styles.BrainGrowth} id="Brain_Growth">
              <div id="Brain_Growth_para">
                <h1>Improve Child's Brain Growth</h1>
                <div className={Styles.bullets}>
                  <ul>
                    <li>
                      Helps in kids brain development and logical thinking.
                    </li>
                    <li>
                      Lessons that will help your child in communication,
                      critical thinking and creativity.
                    </li>
                    <li>Play alone or along with family or friends.</li>
                  </ul>
                </div>
              </div>
              <div className={Styles.left} id="Brain_Growth_img">
                <img src={Stories} alt="ImproveChild" />
              </div>
            </div>
            <div className={Styles.purple}>
              <img src={purple} alt="purple" />
            </div>

            <div className={Styles.goodandFun} id="expertlyTrigger">
              <div className={Styles.goodandFunHead} id="expertly">
                <img src={Rhymes} alt="Rhymes" />
              </div>

              <div className={Styles.goodandFun_right} id="expertlyTest">
                <h1>Expertly</h1>
                <h2>Curated For Kids</h2>
                <div className={Styles.bullets}>
                  <ul>
                    <li>Learning through fun and games</li>
                    <li>
                      Enjoy vast variety of activities, games, stories, video
                      lessons, rhymes etc.
                    </li>
                    <li>
                      Lessons contributing in various fields including Science,
                      Math and Logic, English etc.
                    </li>
                  </ul>
                </div>
                {/* <img
                  src={bendiving}
                  alt="bendiving"
                  className={Styles.bendiving}
                  id="tomastronaut"
                /> */}
              </div>
            </div>

            <img
              src={Tomastronaut}
              alt="Tomastronaut"
              className={Styles.tomastronaut}
              id="tomastronaut"
            />

            <div className={Styles.greenright}>
              <img src={green} alt="green" />
            </div>

            {/* Second */}
            <div className={Styles.parentalAccess} id="parentalAccess">
              <div className={Styles.left}>
                <img
                  src={ParentalAccess}
                  alt="ParentalAccess"
                  id="parentalAccess_img"
                />
              </div>
              <div id="parentalAccess_para" className={Styles.right}>
                <h1>Parental Access</h1>
                <div className={Styles.bullets}>
                  <ul>
                    <li>
                      Helps in kids brain development and logical thinking.
                    </li>
                    <li>
                      Lessons that will help your child in communication,
                      critical thinking and creativity.
                    </li>
                    <li>Play alone or along with family or friends.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={Styles.Carousel} id="carosuelTrigger">
          <div className={Styles.CarouselHeading}>
            <h1>What Parents Say About Us</h1>
          </div>
          <div style={{ marginLeft: "5%" }} id="carousel">
            <Carousel
              customButtonGroup={<ButtonGroup />}
              additionalTransfrom={0}
              arrows={false}
              autoPlay={false}
              autoPlaySpeed={4000}
              centerMode={false}
              containerClass="container-padding-bottom"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass="pdf_carosuel_iteam"
              keyBoardControl
              partialVisible
              minimumTouchDrag={80}
              renderButtonGroupOutside
              renderDotsOutside={false}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable={true}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1650,
                  },
                  items: 4,
                  partialVisibilityGutter: 10,
                },
                mobile: {
                  breakpoint: {
                    max: 1200,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1650,
                    min: 1200,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
            >
              {Array(6)
                .fill()
                .map((item,index) => (
                  <div className={Styles.testimonial} key={index}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <TbUserCircle size={60} color="#a3b5c4" />
                      </div>
                      <div className={Styles.testimonial_para}>
                        <h2>John Deo</h2>
                        <div className={Styles.testimonial_paraSub}>
                          <FaQuoteRight color="#ebeef2" size={10} />
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div> */}
      </div>
    </>
  );
}
